import React from 'react';
import { MainSubStructure, NormalProgrammeComponent } from '../../utils/index';
import CategoryManagementModel from './model';
import styles from './index.less';
import { Form, Input, InputNumber, message, Modal, Select, Tabs } from 'antd';
import { observer } from 'mobx-react';

@observer
export default class CategoryManagement extends React.Component {
  constructor(prop) {
    super(prop);
    this.store = new CategoryManagementModel();
  }

  public declare store: CategoryManagementModel;

  render() {
    const { mainSubStructureModel, formRefTwo, mainSubStructureModelTwo, categoryFirstList, setSecondLevelModalOpen, onSecondLevelSubmit, secondLevelModalOpen, onSubmit, setModalOpen, modalOpen, formRef } = this.store;
    return (
      <div className={styles.page}>
        <Tabs
          className={styles.tab}
          items={[
            {
              label: '一级类别',
              key: '1',
              children: <MainSubStructure store={mainSubStructureModel} />,
            },
            {
              label: '二级类别',
              key: '2',
              children: <MainSubStructure store={mainSubStructureModelTwo} />,
            },
          ]}
        />

        <Form
          scrollToFirstError
          ref={formRef}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Modal
            open={modalOpen}
            onCancel={setModalOpen}
            onOk={onSubmit}
          >
            <div className={styles.modalContent}>
              <Form.Item
                label="排序"
                name="categorySequence"
                extra="*数字越小展示位置越靠前"
                rules={[{ required: modalOpen }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  max={9999}
                />
              </Form.Item>
              <Form.Item
                label="类别"
                name="categoryName"
                extra="*4个汉字长度"
                rules={[{ required: modalOpen }]}
              >
                <Input
                  autoComplete="off"
                  maxLength={4}
                />
              </Form.Item>
              <Form.Item
                label="关联二级"
                name={['children']}
                rules={[{ required: modalOpen }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  maxTagCount="responsive"
                  showSearch
                  optionFilterProp="label"
                  options={categoryFirstList}
                />
              </Form.Item>
            </div>
          </Modal>
        </Form>
        <Form
          scrollToFirstError
          ref={formRefTwo}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 10 }}
        >
          <Modal
            open={secondLevelModalOpen}
            onCancel={setSecondLevelModalOpen}
            onOk={onSecondLevelSubmit}
          >
            <div className={styles.modalContent}>
              <Form.Item
                label="二级类别"
                name="categoryName"
                extra="*4个汉字长度"
                rules={[{ required: secondLevelModalOpen }]}
              >
                <Input
                  autoComplete="off"
                  maxLength={4}
                />
              </Form.Item>
            </div>
          </Modal>
        </Form>
      </div>
    );
  }
}
